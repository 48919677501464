import React from 'react';
import styled from 'styled-components';

import logo from '../img/logo.svg';
import NavMenu from '../general/NavigationBar';
import Button from '../general/Button';

const NavContainer = styled.div``;

const AllThingsWWWNavItems = [
  {
    id: 1, name: 'About', href: '/about', isButtonLink: false,
  },
  {
    id: 2, name: 'Services', href: '/services', isButtonLink: false,
  },
  {
    id: 3, name: 'Contact', href: '/contact-us', isButtonLink: true,
  },
];

const Navbar = () => (
  <NavContainer aria-label="main-navigation">
    <NavMenu navLinksList={AllThingsWWWNavItems} logo={logo} type="center">
      {AllThingsWWWNavItems.map((item) => {
        if (!item.isButtonLink) {
          return null;
        }
        return (<Button to={item.href} key={item.id}>{item.name}</Button>);
      })}
    </NavMenu>
  </NavContainer>
);

export default Navbar;
