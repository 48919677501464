import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const BtnLink = styled(Link)`
  border-radius: 8px;
  padding: 5px 2rem;
  font-size: 18px;
  color: #2E3138;
  outline: none;
  border: 2px solid #2E3138;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  margin-left: 24px;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
`;

const Button = ({ to, children }) => (
  <BtnLink to={to}>
    {children}
  </BtnLink>
);

export default Button;
