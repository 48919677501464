import React from 'react';
import { Link } from 'gatsby';

import logo from '../img/logo.svg';

const Footer = () => (
  <footer className="footer has-background-black has-text-white-ter" style={{ display: 'flex', alignItems: 'top' }}>
    <div className="content has-text-centered">
      <img
        src={logo}
        alt="Kaldi"
        style={{ width: '14em', height: '10em' }}
      />
    </div>
    <div className="content has-text-centered has-background-black has-text-white-ter" style={{ display: 'flex', justifyContent: 'space-evenly', flexGrow: 1 }}>

      <div className="column is-4">
        <section className="menu">
          <ul className="menu-list">
            <li>
              <Link to="/" className="navbar-item">
                Home
              </Link>
            </li>
            <li>
              <Link className="navbar-item" to="/about">
                About
              </Link>
            </li>
            <li>
              <Link className="navbar-item" to="/products">
                Products
              </Link>
            </li>
            <li>
              <Link className="navbar-item" to="/contact/examples">
                Form Examples
              </Link>
            </li>
            <li>
              <a
                className="navbar-item"
                href="/admin/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Admin
              </a>
            </li>
          </ul>
        </section>
      </div>
      <div className="column is-4">
        <section>
          <ul className="menu-list">
            <li>
              <Link className="navbar-item" to="/blog">
                Latest Stories
              </Link>
            </li>
            <li>
              <Link className="navbar-item" to="/contact">
                Contact
              </Link>
            </li>
          </ul>
        </section>
      </div>

    </div>
  </footer>
);

export default Footer;
