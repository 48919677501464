import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const NavLink = styled(Link)`
  color: #2E3138;
  font-size: 18px;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 2rem;
  height: 100%;
  opacity: 0.6;
  cursor: pointer;
  &.active {
    color: #15cdfc;
  }
`;

const NavLinkMobile = styled(Link)``;

const NavItem = ({ children, to = '/' }) => (
  <NavLink to={to} activeStyle>
    {children}
  </NavLink>
);

export const NavItemMobile = ({ children, to = '/' }) => (
  <NavLinkMobile className="bm-item" to={to}>
    {children}
  </NavLinkMobile>
);

export default NavItem;
