import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import 'fontsource-lato';

import { withPrefix } from 'gatsby';
import Footer from './Footer';
import Navbar from './Navbar';

// import './all.sass'
import useSiteMetadata from './SiteMetadata';

const Center = styled.div`
display: flex;
justify-content: center;
`;

export const MaxWidth = styled.div`
  margin: auto;
  max-width: 1060px; 
  /* border: 1px solid #eee; */
  font-family: lato;
  font-size: 24px;
`;

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();
  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}img/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}img/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix('/')}img/og-image.jpg`}
        />
      </Helmet>
      <Center>
        <MaxWidth>
          <Navbar />
          <div>{children}</div>
          <Footer />
        </MaxWidth>
      </Center>
    </div>
  );
};

export default TemplateWrapper;
