import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { ifProp } from 'styled-tools';

const Grow = styled.div`
  ${ifProp('isLeftAligned', css`flex-grow: 1;`, css`flex-grow: 2;`)}
  @media screen and (max-width: 768px) {
    position: absolute;
    top: 2.5rem;
    left: 1rem;
  }
`;

const Logo = ({
  logo, alt = 'All Things WWW', width = '146px', isLeftAligned,
}) => (
  <Grow isLeftAligned={isLeftAligned}>
    <Link to="/" title="Logo">
      <img src={logo} alt={alt} style={{ width }} />
    </Link>
  </Grow>
);

export default Logo;
