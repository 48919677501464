import React from 'react';
import styled, { css } from 'styled-components';
import { bubble as Menu } from 'react-burger-menu';

import { ifProp } from 'styled-tools';
import NavItem, { NavItemMobile } from './NavItem';
import Logo from './Logo';

const actNavItems = [
  {
    id: 1, name: 'About', href: '/about', isButtonLink: false,
  },
  {
    id: 2, name: 'Services', href: '/services', isButtonLink: false,
  },
  {
    id: 3, name: 'Contact', href: '/contact-us', isButtonLink: true,
  },
];

const Nav = styled.nav`
  height: 80px;
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem calc((100vw - 4000px) / 2);
  z-index: 10;
  ${ifProp('isLeftAligned', css`flex-grow: 1;`)}
`;

const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -10px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const NavBtn = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 768px) {
    display: none;
  }
  ${ifProp('isLeftAligned', css`flex-grow: 15; justify-content: flex-end;`)}
  ${ifProp('isCenterAligned', css`flex-grow: 2; justify-content: flex-end;`)}
`;

const NavContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;

const renderNavLinks = (navLinksList) => navLinksList.map((navItem) => {
  if (navItem.isButtonLink) { return null; }
  return (
    <NavItem to={navItem.href} key={navItem.id}>
      {navItem.name}
    </NavItem>
  );
});

const HamburgerMenu = ({ navLinksList = [] }) => (
  <Menu
    right
    pageWrapId="page-wrap"
  >
    {navLinksList.map((navItem) => (
      <NavItemMobile to={navItem.href} key={navItem.id}>
        {navItem.name}
      </NavItemMobile>
    ))}
  </Menu>
);

const Navbar = ({
  navLinksList = actNavItems, logo, type = 'right', children,
}) => {
  if (logo == null) return null;
  return (
    <NavContainer>
      <Logo logo={logo} shrinkLogo={type === 'left' || type === 'center'} />
      <Nav isLeftAligned={type === 'left'} isCenterAligned={type === 'center'}>
        <HamburgerMenu navLinksList={navLinksList} />
        <NavMenu>
          {renderNavLinks(navLinksList)}
        </NavMenu>
      </Nav>
      <NavBtn isLeftAligned={type === 'left'} isCenterAligned={type === 'center'}>
        {children}
      </NavBtn>
    </NavContainer>
  );
};

export default Navbar;
