import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from 'styled-components';
import 'fontsource-raleway';

import Layout, { MaxWidth } from '../components/Layout';

import headerImage from '../img/headerImage.svg';
import successImage from '../img/success.svg';
import rodeoImage from '../img/rodeo.jpg';
import upsideDownSwoopImage from '../img/upsideDownSwoop.svg';
import swoopImage from '../img/swoop.svg';
import wavesImage from '../img/waves.svg';

const Grow = styled.div`
flex-grow: 1;
`;

const H1 = styled.h1`
font-size: 48px;
font-family: raleway;
margin: 10px 0;
`;

const H2Large = styled.h2`
font-size: 36px;
font-weight: bold;
margin: 10px 0 30px;
font-family: raleway;
`;

const H2 = styled.h2`
font-size: 24px;
font-weight: lighter;
margin: 10px 0 30px;
color: #2E3138;
`;

const Button = styled(Link)`
padding: 5px 40px;
height: 32px; 
border: none;
background: #FFBF46;
border-radius: 5px;
color: white;
display: inline-flex;
align-items: center;
text-decoration: none;
&:visited{
  color: white;
}
`;

const Box = styled.div`
display: flex;
align-items: center;
`;

const Section = styled.section`
padding: 75px 0;

`;

const ProjectsSelector = styled.ul`
display: flex;
flex-direction: column;
flex-grow: 1;
margin: 0;
padding: 0;
border-radius: 1px;
`;

const ProjectName = styled.li`
 min-height: 85px;
 background: ${({ active }) => (active ? '#2294F2' : '#03256C')} ;
 min-width: 275px;
 padding: 15px 20px;
 color: white;
 display: inline-flex;
 flex-direction: column;
 justify-content: flex-end;
 font-size: 24px;
 font-weight: lighter;
 flex-grow: 1;

`;
const HorizontalPadding = styled.div`
padding-left: 30px;
padding-right: 30px;
justify-content: center;
text-align: center;
flex-grow: 1;
box-sizing: border-box;
position: relative;
`;

const BigYellowButton = styled(Button)`
  position: absolute;
  padding: 20px 40px;
  bottom: -15px;
  right: 0px;
`;

const Img = styled.img`
width: 100%;
min-width: 25vw;
`;

const ActiveBlue = styled.span`
color: #2294F2;
`;
const Center = styled.div`
display: flex;
align-items: center;
justify-content: center;
`;

const Swoop = styled.div`
background: url('${swoopImage}');
height: 67px;
background-repeat: no-repeat;
width: 100%;
background-position: right;
`;

const UpsideDownSwoop = styled(Swoop)`
background: url('${upsideDownSwoopImage}');
background-repeat: no-repeat;
background-position: left;
`;

const BreakOutSection = styled.div`
position: relative;
justify-content: center;
text-align: center;
margin-left: calc(50% - 50vw);
margin-right: calc(50% - 50vw);
justify-content: center;

`;

const BreakOutSectionWaves = styled(BreakOutSection)`
background: url('${wavesImage}');
position: absolute;
bottom: 250px;
height: 700px;
width: 100%;
z-index: -1;
background-repeat: no-repeat;
transform-origin: left;
transform: rotate(7deg);
background-size: cover;
`;

const BreakOutSectionBlue = styled(BreakOutSection)`
background: #03256C;
color: white;

`;

const Max45 = styled.div`
max-width: 450px;
`;

const ColoredBox = styled.div`
background-color: ${({ color }) => color};
border-radius: 5px;
border: none;
color: white;
max-width: 250px;
padding: 30px;
`;

const SpaceEvenly = styled.div`
display: flex;
justify-content: space-around;
`;
const SmallTitle = styled.h4`
font-size: 14px;
margin: 0;
text-transform: uppercase;
font-weight: lighter;
padding-top: 45px;
`;
const ServiceList = styled.ul`
margin: 0;
padding: 0;
margin-right: 30px;
flex-grow: 1;
`;

const ServiceListItem = styled.li`
list-style-type: none;
margin: 10px 0;
color: #2E3138;
font-weight: lighter;

`;
const SpaceBetween = styled.div`
display: flex;
justify-content: space-between;
`;

const GreyText = styled.div`
color: #2E3138;
`;
export const IndexPageTemplate = () => (
  <div>
    <Section>
      <Box>
        <Grow>
          <H1>
            Web Design &amp;
            {' '}
            <br />
            Marketing Simplified
          </H1>
          <H2>Big Business Tools for Small Business Owners</H2>
          <Button to="/services">Learn More</Button>
        </Grow>

        <div><Img src={headerImage} /></div>
      </Box>
    </Section>
    <Section>
      <div>
        <H2Large>
          What can we do
          <br />
          <ActiveBlue>for your brand?</ActiveBlue>

        </H2Large>
      </div>
      <SpaceBetween>
        <ServiceList>
          <ServiceListItem>Website Design / Redesign</ServiceListItem>
          <ServiceListItem>UI / UX Design</ServiceListItem>
          <ServiceListItem>Website Development</ServiceListItem>
          <ServiceListItem>Content Writing</ServiceListItem>
        </ServiceList>
        <ServiceList>
          <ServiceListItem>SEO &amp; Online Marketing</ServiceListItem>
          <ServiceListItem>Custom E-commerce</ServiceListItem>
          <ServiceListItem>Custom Web Apps</ServiceListItem>
          <ServiceListItem>Customer Service</ServiceListItem>
        </ServiceList>
        <ServiceList>
          <ServiceListItem>Corporate Identity</ServiceListItem>
          <ServiceListItem>Logo Design</ServiceListItem>
          <ServiceListItem>Google Adwords</ServiceListItem>
          <ServiceListItem>Code Review</ServiceListItem>
        </ServiceList>
      </SpaceBetween>
    </Section>
    <Section>
      <div>
        <H2Large>Our Projects</H2Large>
        <Box>
          <Box>
            <ProjectsSelector>
              <ProjectName active>Rodeo Spas</ProjectName>
              <ProjectName>Jumpmaxx</ProjectName>
              <ProjectName>Fenari Art</ProjectName>
            </ProjectsSelector>
          </Box>
          <HorizontalPadding>
            <Img src={rodeoImage} />
            <BigYellowButton>Check it out</BigYellowButton>
          </HorizontalPadding>
        </Box>
      </div>
    </Section>
    <BreakOutSection>
      <BreakOutSectionWaves />
      <MaxWidth>
        <Section>
          <Section>
            <div>
              <Center>
                <H2Large>
                  Work
                  {' '}
                  <ActiveBlue>with us</ActiveBlue>
                  {' '}
                  because
                </H2Large>
              </Center>
              <SpaceEvenly>
                <ColoredBox color="#FFBF46">
                  <SmallTitle>Local</SmallTitle>
                  <div>We are based in Tucson and understand</div>
                </ColoredBox>
                <ColoredBox color="#03256C">
                  <SmallTitle>Experts</SmallTitle>
                  <div>
                    We are based in Tucson and understand what&apos;s need
                    to succeed in the local market.
                  </div>
                </ColoredBox>
                <ColoredBox color="#2294F2">
                  <SmallTitle>Unique</SmallTitle>
                  <div>
                    We are based in Tucson and understand what&apos;s need
                    to succeed in the local market.
                  </div>
                </ColoredBox>
              </SpaceEvenly>
            </div>
          </Section>
        </Section>
      </MaxWidth>
    </BreakOutSection>
    <Section>
      <div style={{ display: 'flex', alignItems: 'center' }}>

        <Max45>
          <H2Large>
            We help your
            <br />
            <ActiveBlue>business succeed</ActiveBlue>

          </H2Large>
          <Box>
            <GreyText>
              Text about how we work side-to-side with these business,
              help them make more profit, etc
            </GreyText>
          </Box>
        </Max45>

        <HorizontalPadding><Img src={successImage} /></HorizontalPadding>
      </div>
    </Section>
    <BreakOutSectionBlue>
      <MaxWidth>
        <UpsideDownSwoop />
        <Section>
          <H2Large>Interested in Working together?</H2Large>
          <Button>Contact us</Button>
        </Section>
        <Swoop />
      </MaxWidth>
    </BreakOutSectionBlue>

  </div>
);

const IndexPage = ({ data }) => {
  const { frontmatter } = data.page;

  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
        featuredImage={frontmatter.featuredImage}
      />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query AboutPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subtitle
        featuredImage
      }
    }
  }
`;
